import React, { useState } from "react";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Button } from "semantic-ui-react";
import ModalWrapper from "../../../modals/ModalWrapper";
import { Form, Formik } from "formik";
import MyTextInput from "../../../forms/MyTextInput";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../LoadingComponent";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateLocationInFirestore } from "../../../../firestore/firestoreService";
import { closeModal } from "../../../modals/modalReducer";

export default function AddPhone(location) {
  const { loading, error } = useSelector((state) => state.async);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [originalPhone] = useState(location.phones);
  const [phoneCount, setPhoneCount] = useState(0);
  const initialValues = {
    id: uuidv4(),
    misc: "",
    numName: "",
    number: "",
    provider: "",
  };

  const validationSchema = Yup.object({
    numName: Yup.string().required("Please enter a phone name."),
    number: Yup.string().required("Must enter a phone number"),
    misc: Yup.string(),
    provider: Yup.string(),
  });

  const finalSubmitAllPhone = (location) => {
    updateLocationInFirestore(location);
    dispatch(closeModal());
    toast.success(
      "Your new phone/phones have been added successfully. If your new phone/s do not appear right away please refresh your page.",
      { autoClose: 6000 }
    );
  };

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) navigate("/error");
  return (
    <ModalWrapper size="large" header="Add Phone/Phones">
      {phoneCount > 0 && <p>New Hardwares Added so far: {phoneCount}</p>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          originalPhone.push(values);
          setPhoneCount(phoneCount + 1);
          resetForm({
            values: {
              id: uuidv4(),
              misc: "",
              numName: "",
              number: "",
              provider: "",
            },
          });
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <MyTextInput
              label="Number Name"
              name="numName"
              placeholder="Required"
            />
            <MyTextInput
              label="Phone Number"
              name="number"
              placeholder="Required"
            />
            <MyTextInput label="Provider" name="provider" />
            <MyTextInput label="Misc" name="misc" />
            <Button type="submit" icon="plus" color="blue" />
          </Form>
        )}
      </Formik>
      <Button
        onClick={() => finalSubmitAllPhone(location)}
        icon="save"
        style={{ marginTop: 8 }}
        color="red"
        content="Save all new hardware to database"
      />
    </ModalWrapper>
  );
}
