import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { signOutFirebase } from "../../firestore/firebaseService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function SignedInMenu() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const navigate = useNavigate();

  async function handleSignout() {
    try {
      navigate("/");
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Menu.Item position="right">
      <Dropdown pointing="top right" text={currentUserProfile?.displayName}>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/profile/${currentUserProfile?.id}`}>
            <Icon color="grey" name="user" /> My Profile
          </Dropdown.Item>
          <Dropdown.Item onClick={handleSignout}>
            <Icon color="grey" name="sign-out" /> Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
}
