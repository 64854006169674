import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Form,
  Grid,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import LocationHardware from "./locationDevices/LocationHardware";
import LocationPhones from "./locationDevices/LocationPhones";
import LocationHeader from "./LocationHeader";
import NavBar from "../../navigation/NavBar";
import { useDispatch, useSelector } from "react-redux";
// import { deleteLocation } from "../redux/locationActions";
import useFirestoreDoc from "../../../hooks/useFirestoreDoc";
import {
  deleteLocationInFirestore,
  listenToLocationFromFirestore,
} from "../../../firestore/firestoreService";
import { listenToLocations } from "../redux/locationActions";
import { toast } from "react-toastify";
import { openModal } from "../../modals/modalReducer";
import DownloadLocation from "./DownloadLocation";

export default function LocationDetailsPage() {
  const { id } = useParams();
  const [deleteBtn, setDeleteBtn] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useSelector((state) =>
    state.locations.locations.find((l) => l.id === id)
  );
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => listenToLocationFromFirestore(id),
    data: (location) => dispatch(listenToLocations([location])),
    deps: [id, dispatch],
  });

  if (loading || (!location && !error))
    return (
      <Dimmer>
        <Loader>Loading</Loader>
      </Dimmer>
    );

  if (error) navigate("/error");

  const deleteCheckbox = () => {
    setDeleteBtn(!deleteBtn);
  };

  return (
    <>
      {location ? (
        <>
          <Container className="main">
            <NavBar />
            <Button basic icon onClick={() => navigate("/locations")}>
              <Icon name="home" />
            </Button>
            <DownloadLocation location={location} />

            <Button
              color="teal"
              content="View Location Files"
              icon="file"
              labelPosition="right"
              floated="right"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "LocationFilesDashboard",
                    modalProps: location,
                  })
                )
              }
            />
            <Button
              color="blue"
              content="Upload File"
              icon="upload"
              labelPosition="right"
              floated="right"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "UploadFile",
                    modalProps: location,
                  })
                )
              }
            />
            <Grid
              style={{
                marginTop: 10,
                width: "100%",
              }}
              columns={1}
            >
              <Grid.Row>
                <Grid.Column>
                  <LocationHeader location={location} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>Notes: {location.notes}</Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column>
                  <LocationHardware location={location} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <LocationPhones location={location} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Segment placeholder>
            <Form.Field
              onChange={() => deleteCheckbox()}
              control={Checkbox}
              label="Click here to delete this entire location, including all data and files associated with this location"
            />

            <Button
              color="red"
              content="Permanently delete this location"
              disabled={deleteBtn}
              onClick={(e) => {
                if (
                  window.confirm(
                    "Are you sure you want to permanently delete this location?"
                  )
                ) {
                  deleteLocationInFirestore(location.id);
                  toast.success(
                    "Your location and its files have been deleted permanently."
                  );
                  navigate("/locations");
                }
              }}
            />
          </Segment>
        </>
      ) : (
        <p>No info</p>
      )}
    </>
  );
}
