import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
  FETCH_LOCATIONS,
} from "./locationConstants";

export function listenToLocations(locations) {
  return {
    type: FETCH_LOCATIONS,
    payload: locations,
  };
}

export function createLocation(location) {
  return {
    type: CREATE_LOCATION,
    payload: location,
  };
}

export function updateLocation(location) {
  return {
    type: UPDATE_LOCATION,
    payload: location,
  };
}

export function deleteLocation(locationId) {
  return {
    type: DELETE_LOCATION,
    payload: locationId,
  };
}
