import "./App.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./componants/PrivateRoute";
import LocationDashboard from "./componants/locations/LocationDashboard";
import HomePage from "./componants/HomePage";
import Page404 from "./componants/Page404";
import LocationDetailsPage from "./componants/locations/locationDetails/LocationDetailsPage";
import AddNewLocationForm from "./componants/forms/AddNewLocationForm";
import ProfilePage from "./componants/profile/ProfilePage";
import ModalManager from "./componants/modals/ModalManager";
import ErrorComponent from "./componants/ErrorComponent";
import { ToastContainer } from "react-toastify";
import LocationFilesDashboard from "./componants/locations/locationDetails/locationFiles/LocationFilesDashboard";

function App() {
  return (
    <>
      <ModalManager />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/locations" element={<LocationDashboard />} />
          <Route path="/location/:id" element={<LocationDetailsPage />} />
          <Route path="/newlocation" element={<AddNewLocationForm />} />
          <Route path="/profile/:id" element={<ProfilePage />} />
          <Route path={"/files/:id"} element={<LocationFilesDashboard />} />
        </Route>
        <Route path="*" element={<Page404 />} />
        <Route path="/error" component={<ErrorComponent />} />
      </Routes>
      <ToastContainer hideProgressBar position="top-right" />
    </>
  );
}

export default App;
