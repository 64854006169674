import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Header, Segment, Table, Button, Grid, Ref } from "semantic-ui-react";
import { openModal } from "../../../modals/modalReducer";
import { toast } from "react-toastify";
import { updatePhones } from "../../../../firestore/firestoreService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function LocationPhones({ location }) {
  const dispatch = useDispatch();
  const { phones, id } = location;
  const [dragging, setDragging] = useState(false);
  const [phoneList, setPhoneList] = useState(phones);

  const deleteThisPhone = async (locationID, phoneID) => {
    try {
      const newPhones = phones.filter((p) => p.id !== phoneID);
      await updatePhones(locationID, newPhones);
      toast.success("Your phone has been delete successfully.");
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDragDrop = async (result) => {
    const { destination, source } = result;

    setDragging(false);
    if (!result.destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newPhoneOrder = Object.assign([], phoneList);
    const project = phoneList[source.index];
    newPhoneOrder.splice(source.index, 1);
    newPhoneOrder.splice(destination.index, 0, project);
    setPhoneList(newPhoneOrder);
    updatePhones(id, newPhoneOrder);
  };

  const getPhoneDNDStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "orange" : "#E3E3E3",
    pointerEvents: "auto",
    cursor: isDragging ? "all-scroll" : "pointer",
    ...draggableStyle,
  });

  const phoneStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#333" : "#E3E3E3",
    cursor: "all-scroll",
  });
  return (
    <>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column floated="left">
            <Header as="h4">Phones</Header>
            {location && (
              <Button
                size="tiny"
                icon="plus"
                style={{ marginBottom: 10, borderRadius: 25 }}
                color="green"
                onClick={() =>
                  dispatch(
                    openModal({
                      modalType: "AddPhone",
                      modalProps: location,
                    })
                  )
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {phoneList ? (
        <div>
          <DragDropContext
            onDragEnd={handleDragDrop}
            onDragStart={(rs) => setDragging(true)}
          >
            <Table style={{ marginBottom: 140 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Phone #</Table.HeaderCell>
                  <Table.HeaderCell>Name/Location</Table.HeaderCell>
                  <Table.HeaderCell>Provider</Table.HeaderCell>
                  <Table.HeaderCell>Vendor & Support #</Table.HeaderCell>
                  <Table.HeaderCell>Misc.</Table.HeaderCell>
                  <Table.HeaderCell>Update</Table.HeaderCell>
                  <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body
                      style={phoneStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                      className="droppable"
                    >
                      {phoneList &&
                        phoneList.map((p, index) => (
                          <Draggable
                            draggableId={p.id}
                            index={index}
                            key={p.id}
                          >
                            {(provided, snapshot) => (
                              <Ref innerRef={provided.innerRef}>
                                <Table.Row
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getPhoneDNDStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Table.Cell>{p.number}</Table.Cell>
                                  <Table.Cell>{p.numName}</Table.Cell>
                                  <Table.Cell>{p.provider}</Table.Cell>
                                  <Table.Cell>{p.support}</Table.Cell>
                                  <Table.Cell>{p.misc}</Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      icon="edit outline"
                                      color="olive"
                                      onClick={() =>
                                        dispatch(
                                          openModal({
                                            modalType: "EditPhone",
                                            modalProps: { location, p },
                                          })
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      icon="trash alternate outline"
                                      color="red"
                                      onClick={() => deleteThisPhone(id, p.id)}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Ref>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </div>
      ) : (
        <Segment>No phones for this location</Segment>
      )}
    </>
  );
}
