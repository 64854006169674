import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAM-2cKaL2e5hDd_W0YX31L14SwAyqTFT0",
  authDomain: "site-bible-production-new.firebaseapp.com",
  databaseURL: "https://site-bible-production-new-default-rtdb.firebaseio.com",
  projectId: "site-bible-production-new",
  storageBucket: "site-bible-production-new.appspot.com",
  messagingSenderId: "390000233236",
  appId: "1:390000233236:web:9bf6fde4842df39b965001",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
