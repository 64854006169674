import React from "react";
import ModalWrapper from "../modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SBLogo from "../../assets/sb-logo.png";
import { Button, Label, Image, Container } from "semantic-ui-react";
import MyTextInput from "../forms/MyTextInput";
import { useDispatch } from "react-redux";
import { closeModal } from "../modals/modalReducer";
import { signInWithEmail } from "../../firestore/firebaseService";

export default function LoginForm() {
  const dispatch = useDispatch();
  return (
    <div>
      <ModalWrapper size="mini">
        <Container style={{ textAlign: "center" }}>
          <Image src={SBLogo} size="small" centered />
          <p>Login</p>
        </Container>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
            password: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await signInWithEmail(values);
              setSubmitting(false);
              dispatch(closeModal());
            } catch (error) {
              setErrors({ auth: "Problem with username and/or password" });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui form">
              <MyTextInput name="email" placeholder="Enter Email address" />
              <MyTextInput
                name="password"
                placeholder="Enter password"
                type="password"
              />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                fluid
                size="large"
                content="Login"
              />
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </div>
  );
}
