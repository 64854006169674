import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Header, Segment, Table, Grid, Ref } from "semantic-ui-react";
import { toast } from "react-toastify";
import { openModal } from "../../../modals/modalReducer";
import { updateHardware } from "../../../../firestore/firestoreService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function LocationHardware({ location }) {
  const { hardware, id } = location;
  const [dragging, setDragging] = useState(false);
  const [hardwareList, setHardwareList] = useState(hardware);
  const dispatch = useDispatch();

  async function deleteThisHardware(originalLocationid, hardwareid) {
    try {
      const newHardware = hardware.filter((hw) => hw.id !== hardwareid);
      await updateHardware(originalLocationid, newHardware);
      window.location.reload();
      toast.success("Your hardware has been successfully deleted.");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleDragDrop = async (result) => {
    const { destination, source } = result;
    setDragging(false);
    if (!result.destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newHardwareOrder = Object.assign([], hardwareList);
    const project = hardwareList[source.index];
    newHardwareOrder.splice(source.index, 1);
    newHardwareOrder.splice(destination.index, 0, project);
    setHardwareList(newHardwareOrder);
    updateHardware(id, newHardwareOrder);
  };

  const getHardwareDNDStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "orange" : "#E3E3E3",
    pointerEvents: "auto",
    cursor: isDragging ? "all-scroll" : "pointer",
    ...draggableStyle,
  });

  const hardwareStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#333" : "#E3E3E3",
    cursor: "all-scroll",
  });

  return (
    <>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h4">Hardware</Header>
            {location && (
              <Button
                size="tiny"
                icon="plus"
                style={{ marginBottom: 10, borderRadius: 25 }}
                color="green"
                onClick={() =>
                  dispatch(
                    openModal({
                      modalType: "AddHardware",
                      modalProps: location,
                    })
                  )
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {hardwareList ? (
        <div>
          <DragDropContext
            onDragEnd={handleDragDrop}
            onDragStart={(rs) => setDragging(true)}
          >
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Device</Table.HeaderCell>
                  <Table.HeaderCell>Int. IP</Table.HeaderCell>
                  <Table.HeaderCell>Ext. IP</Table.HeaderCell>
                  <Table.HeaderCell>Serial</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell>Vendor & Support</Table.HeaderCell>
                  <Table.HeaderCell>Update</Table.HeaderCell>
                  <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body
                      style={hardwareStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                      className="droppable"
                    >
                      {hardwareList &&
                        hardwareList.map((h, index) => (
                          <Draggable
                            draggableId={h.id}
                            index={index}
                            key={h.id}
                          >
                            {(provided, snapshot) => (
                              <Ref innerRef={provided.innerRef}>
                                <Table.Row
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getHardwareDNDStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Table.Cell>{h.device}</Table.Cell>
                                  <Table.Cell>{h.ipAddress}</Table.Cell>
                                  <Table.Cell>{h.extIP}</Table.Cell>
                                  <Table.Cell>{h.serial}</Table.Cell>
                                  <Table.Cell>{h.hardwareLocation}</Table.Cell>
                                  <Table.Cell>{h.support}</Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      icon="edit outline"
                                      color="olive"
                                      onClick={() =>
                                        dispatch(
                                          openModal({
                                            modalType: "EditHardware",
                                            modalProps: { location, h },
                                          })
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      onClick={() =>
                                        deleteThisHardware(id, h.id)
                                      }
                                      color="red"
                                      icon="trash"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Ref>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </div>
      ) : (
        <Segment>No hardware for this location</Segment>
      )}
    </>
  );
}
