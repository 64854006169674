import React from "react";
import { useSelector } from "react-redux";
import EditHardware from "../locations/locationDetails/locationDevices/EditHardware";
import EditPhone from "../locations/locationDetails/locationDevices/EditPhone";
import EditLocation from "../locations/locationDetails/EditLocation";
import AddHardware from "../locations/locationDetails/locationDevices/AddHardware";
import AddPhone from "../locations/locationDetails/locationDevices/AddPhone";
import LoginForm from "../auth/LoginForm";
import UploadFile from "../locations/locationDetails/locationFiles/UploadFile";
import LocationFilesDashboard from "../locations/locationDetails/locationFiles/LocationFilesDashboard";

export default function ModalManager() {
  const modalLookup = {
    EditHardware,
    EditPhone,
    EditLocation,
    AddHardware,
    AddPhone,
    LoginForm,
    LocationFilesDashboard,
    UploadFile,
  };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
