import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationFiles } from "../../../../firestore/firestoreService";
import { listenToFiles } from "./FilesActions";
import useFirestoreCollection from "../../../../hooks/useFirestoreCollection";
import { List, Segment, Header, Button, Icon } from "semantic-ui-react";
import ModalWrapper from "../../../modals/ModalWrapper";
import {
  downloadFile,
  deleteFile,
} from "../../../../firestore/firestoreService";

export default function LocationFilesDashboard(location) {
  const { id } = location;
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.files);

  useFirestoreCollection({
    query: () => getLocationFiles(id),
    data: (files) => dispatch(listenToFiles(files)),
    deps: [id, dispatch],
  });

  return (
    <ModalWrapper size="small">
      <Segment>
        <Header as="h3" content={location.name} />
        {files.length !== 0 ? (
          files.map((file) => (
            <List key={file.id} bulleted>
              <List.Item>
                <Button onClick={(f) => downloadFile(file.hashedFileName)}>
                  {file.originalFileName}
                </Button>
                <Button
                  style={{ marginLeft: 11 }}
                  size="tiny"
                  onClick={() => deleteFile(file.id, file.hashedFileName)}
                  color="red"
                >
                  <Icon name="close" />
                </Button>
              </List.Item>
            </List>
          ))
        ) : (
          <Header as="h4">This location has no files.</Header>
        )}
      </Segment>
    </ModalWrapper>
  );
}
