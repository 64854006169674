import React, { useRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import DownloadDocument from "./DownloadDocument";
import { jsPDF } from "jspdf";

export default function DownloadLocation({ location }) {
  const locationTemplateRef = useRef(null);

  const handleDownloadLocation = async () => {
    locationTemplateRef.current.hidden = false;
    const content = locationTemplateRef.current;

    let pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "letter",
    });
    await pdf.html(content, {
      async callback(doc) {
        await doc.save(`${location.name}.pdf`);
      },
      x: 0,
      y: 0,
      margin: [10, 10, 10, 10],
      autoPaging: "text",
    });
    locationTemplateRef.current.hidden = true;
  };

  return (
    <>
      <div hidden ref={locationTemplateRef}>
        <DownloadDocument location={location} />
      </div>
      <Button onClick={handleDownloadLocation} icon labelPosition="right">
        Download Location
        <Icon name="download" />
      </Button>
    </>
  );
}

// const content = locationTemplateRef.current;
// console.log(content);
// let pdf = new jsPDF({
//   orientation: "portrait",
//   unit: "px",
//   format: "a4",
// });
// pdf.html(content, {
//   async callback(doc) {
//     await doc.save(`___${location.name}___.pdf`);
//   },
//   x: 10,
//   y: -2800,
// });

// const content = locationTemplateRef.current;
// let pdf = new jsPDF("p", "pt", "letter");
// const data = {
//   callback: function (pdf) {
//     pdf.save(`___${location.name}___.pdf`);
//   },
//   x: 10,
//   y: -2800,
//   margin: [10, 10, 10, 10],
//   autoPaging: "text",
// };

// pdf.html(content, data);
