import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleLocation from "./SingleLocation";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { listenToLocationsFromFirestore } from "../../firestore/firestoreService";
import { listenToLocations } from "./redux/locationActions";

export default function Locations() {
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state.locations);
  const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToLocationsFromFirestore(),
    data: (locations) => dispatch(listenToLocations(locations)),
    deps: [dispatch],
  });
  return (
    <Grid textAlign="center" stackable>
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <Grid.Row columns={6}>
          {locations
            .sort((a, b) => {
              if (a.category > b.category) {
                return 1;
              } else if (a.category < b.category) {
                return -1;
              }
              const textA = a.name.toUpperCase();
              const textB = b.name.toUpperCase();
              if (textA < textB) {
                return -1;
              } else if (textA > textB) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((location) => (
              <SingleLocation key={location.id} location={location} />
            ))}
        </Grid.Row>
      )}
    </Grid>
  );
}
