import React from "react";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../../modals/ModalWrapper";
import { Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../forms/MyTextInput";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../LoadingComponent";
import { updateHardware } from "../../../../firestore/firestoreService";
import { closeModal } from "../../../modals/modalReducer";

export default function EditHardware({ location, h }) {
  const dispatch = useDispatch();
  const { id, device, hardwareLocation, extIP, ipAddress, serial, support } = h;
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.async);
  const originalLocation = useSelector((state) =>
    state.locations.locations.find((l) => l.id === location.id)
  );
  const { hardware } = originalLocation;

  const initialValues = {
    id: id || "",
    device: device || "",
    hardwareLocation: hardwareLocation || "",
    ipAddress: ipAddress || "",
    extIP: extIP || "",
    serial: serial || "",
    support: support || "",
  };

  const validationSchema = Yup.object({
    device: Yup.string().required("Please enter a device name."),
    hardwareLocation: Yup.string(),
    ipAddress: Yup.string(),
    serial: Yup.string(),
    extIP: Yup.string(),
    support: Yup.string(),
  });

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) navigate("/error");

  return (
    <div>
      <ModalWrapper size="large" header="Edit Hardware">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            try {
              const foundHardware = hardware.findIndex(
                (h) => h.id === values.id
              );
              hardware[foundHardware] = values;
              updateHardware(originalLocation.id, hardware);
              toast.success(`Your hardware has been updated.`);
              dispatch(closeModal());
            } catch (e) {
              toast.error(`${e.message}`);
              console.log(e.message);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="ui form">
              <MyTextInput label="Device Name" name="device" />
              <MyTextInput
                label="Hardware Location"
                name="hardwareLocation"
                placeholder="Where is the hardware located"
              />
              <MyTextInput label="IP Address" name="ipAddress" />
              <MyTextInput label="Serial Number" name="serial" />
              <MyTextInput label="External IP" name="extIP" />
              <MyTextInput label="Support Info" name="support" />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                color="blue"
                content="Submit"
              />
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </div>
  );
}
