import React from "react";
import { useNavigate } from "react-router-dom";
import ITLogo from "../assets/doc-it-logo.png";
import SBLogo from "../assets/sb-logo.png";
import { Container, Icon, Segment, Button, Image } from "semantic-ui-react";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <Segment inverted vertical textAlign="center" className="masthead">
      <Container>
        <Image
          style={{ marginBottom: 10 }}
          src={ITLogo}
          size="large"
          centered
        />
        <Image style={{ padding: 10 }} src={SBLogo} size="medium" centered />

        <Button
          icon
          labelPosition="left"
          onClick={() => navigate("/locations")}
        >
          <Icon name="sign-in" />
          Login
        </Button>
      </Container>
    </Segment>
  );
}
