import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "semantic-ui-react";
import { openModal } from "../../modals/modalReducer";

export default function LocationHeader({ location }) {
  const dispatch = useDispatch();
  return (
    <>
      {location ? (
        <>
          <Table textAlign="center" style={{ marginTop: 16 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Location</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Main Contact #</Table.HeaderCell>
                <Table.HeaderCell>Site Main Contact</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{location.name}</Table.Cell>
                <Table.Cell>{location.address}</Table.Cell>
                <Table.Cell>{location.mainPhone}</Table.Cell>
                <Table.Cell>{location.siteContact}</Table.Cell>
                <Table.Cell>
                  <Button
                    style={{ marginBottom: 10 }}
                    icon="edit outline"
                    color="olive"
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: "EditLocation",
                          modalProps: location,
                        })
                      )
                    }
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Internet Speed</Table.HeaderCell>
                <Table.HeaderCell>External IP Address</Table.HeaderCell>
                <Table.HeaderCell>Diocesan Network</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{location.internet}</Table.Cell>
                <Table.Cell>{location.externalIP}</Table.Cell>
                <Table.Cell>{location.diocesanNetwork}</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Vendor & Support</Table.HeaderCell>
                <Table.HeaderCell>Modem Credentials</Table.HeaderCell>
                <Table.HeaderCell>Local IP Range</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{location.support}</Table.Cell>
                <Table.Cell>{location.modem}</Table.Cell>
                <Table.Cell>{location.localRange}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      ) : null}
    </>
  );
}
