import React from "react";

export default function DownloadDocument({ location }) {
  const {
    name,
    address,
    mainPhone,
    internet,
    siteContact,
    modem,
    externalIP,
    diocesanNetwork,
    support,
    hardware,
    phones,
    localRange,
    notes,
  } = location;

  return (
    <>
      <h1>{name}</h1>
      <ul style={{ listStyle: "none" }}>
        <li>{address}</li>
        <li>{mainPhone}</li>
        <li>{siteContact}</li>
        <li>Internet Speed: {internet}</li>
        <li>Ext. IP: {externalIP}</li>
        <li>Diocesan Network: {diocesanNetwork}</li>
        <li>Vendor Support: {support}</li>
        <li>Modem Creds. {modem}</li>
        <li>Local IP Range: {localRange}</li>
      </ul>

      <table style={{ fontSize: 11, width: 500 }}>
        <thead>
          <tr>
            <th>Device</th>
            <th>Location</th>
            <th>IP Addr.</th>
            <th>Serial</th>
          </tr>
        </thead>

        {hardware &&
          hardware.map((h) => (
            <tbody key={h.id}>
              <tr>
                <td>{h.device}</td>
                <td>{h.hardwareLocation}</td>
                <td>{h.ipAddress}</td>
                <td>{h.serial}</td>
              </tr>
            </tbody>
          ))}
      </table>
      <table style={{ fontSize: 11, width: 500 }}>
        <thead>
          <tr>
            <th>Phone #</th>
            <th>Location</th>
            <th>Provider</th>
            <th>Support</th>
          </tr>
        </thead>

        {phones &&
          phones.map((p) => (
            <tbody key={p.id}>
              <tr>
                <td>{p.number}</td>
                <td>{p.numName}</td>
                <td>{p.provider}</td>
                <td>{p.misc}</td>
              </tr>
            </tbody>
          ))}
      </table>
      <p style={{ marginTop: 11, width: 400 }}>Notes: {notes}</p>
    </>
  );
}
