import { combineReducers } from "redux";
import modalReducer from "../modals/modalReducer";
import locationReducer from "../locations/redux/locationReducer";
import asyncReducer from "../../async/asyncReducer";
import profileReducer from "../profile/profileReducer";
import authReducer from "../auth/authReducer";
import filesReducer from "../locations/locationDetails/locationFiles/FilesReducer";

const rootReducer = combineReducers({
  locations: locationReducer,
  modals: modalReducer,
  async: asyncReducer,
  profile: profileReducer,
  auth: authReducer,
  files: filesReducer,
});

export default rootReducer;
