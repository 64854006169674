import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import MyTextInput from "../forms/MyTextInput";
import { Container, Header, Button, Label } from "semantic-ui-react";
import { updateUserPassword } from "../../firestore/firebaseService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ProfileContent({ profile, isCurrentUser }) {
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <Container style={{ marginTop: 140 }}>
      <Button icon="home" basic onClick={() => navigate("/locations")} />
      <Header as="h1" content={profile.displayName} />
      <Header as="h2" content={profile.email} />
      {currentUser.providerId === "password" && (
        <>
          <p>Reset your password here:</p>
          <Formik
            initialValues={{ newPassword1: "", newPassword2: "" }}
            validationSchema={Yup.object({
              newPassword1: Yup.string().required("Password is required"),
              newPassword2: Yup.string().oneOf(
                [Yup.ref("newPassword1"), null],
                "Passwords do not match"
              ),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, resetForm }
            ) => {
              try {
                await updateUserPassword(values);
                toast.success("Success: you have updated your password.");
                resetForm();
              } catch (error) {
                toast.error(error.message);
                setErrors({ auth: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ errors, isSubmitting, isValid, dirty }) => (
              <Form className="ui form">
                <MyTextInput
                  name="newPassword1"
                  type="password"
                  placeholder="New password"
                />
                <MyTextInput
                  name="newPassword2"
                  type="password"
                  placeholder="Confirm new password"
                />
                {errors.auth && (
                  <Label
                    basic
                    color="red"
                    style={{ marginBottom: 10 }}
                    content={errors.auth}
                  />
                )}
                <Button
                  style={{ display: "block" }}
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting || !dirty}
                  size="large"
                  positive
                  content="Update password"
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
}
