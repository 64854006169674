import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import ModalWrapper from "../../modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../forms/MyTextInput";
import MySelectInput from "../../forms/MySelectInput";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../LoadingComponent";
import { updateLocationInFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../modals/modalReducer";

export default function EditLocation(location) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const originalLocation = useSelector((state) =>
    state.locations.locations.find((l) => l.id === location.id)
  );
  const { loading, error } = useSelector((state) => state.async);

  const initialValues = {
    id: originalLocation.id,
    name: originalLocation.name,
    address: originalLocation.address,
    category: originalLocation.category,
    mainPhone: originalLocation.mainPhone,
    siteContact: originalLocation.siteContact,
    internet: originalLocation.internet,
    externalIP: originalLocation.externalIP,
    diocesanNetwork: originalLocation.diocesanNetwork,
    support: originalLocation.support,
    modem: originalLocation.modem,
    localRange: originalLocation.localRange,
    hardware: originalLocation.hardware,
    phones: originalLocation.phones,
    notes: originalLocation.notes,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter a name for this location"),
    address: Yup.string().required(
      "Please eneter an address for this location"
    ),
    category: Yup.string().required("Please select a category."),
    mainPhone: Yup.string().required(
      "Please enter a main contact phone number"
    ),
    internet: Yup.string(),
    externalIP: Yup.string(),
    diocesanNetwork: Yup.string(),
    support: Yup.string(),
    modem: Yup.string(),
    localRange: Yup.string(),
    notes: Yup.string(),
    id: Yup.string(),
  });
  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) navigate("/error");

  return (
    <ModalWrapper size="large" header={`Edit: ${location.name}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          try {
            updateLocationInFirestore(values);
            toast.success("Your location has been updated.");
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <MyTextInput
              label="Location Name"
              name="name"
              placeholder="Name of location"
            />
            <MySelectInput
              name="category"
              label="Location Category"
              placeholder="Select a category here"
              options={[
                { key: "cc", value: "CC", text: "CC" },
                { key: "cem", value: "CEM", text: "CEM" },
                { key: "doh", value: "DOH", text: "DOH" },
                { key: "doc", value: "DOC", text: "DOC" },
              ]}
            />
            <MyTextInput
              label="Address"
              name="address"
              placeholder="Address of location"
            />
            <MyTextInput
              label="Main Phone"
              name="mainPhone"
              placeholder="Main Phone Number"
            />
            <MyTextInput
              name="siteContact"
              label="Site's Main Contact Info"
              placeholder="Enter main contact for this location"
            />
            <MyTextInput
              label="Internet Speed"
              name="internet"
              placeholder="Internet Speed"
            />
            <MyTextInput
              label="External IP"
              name="externalIP"
              placeholder="External IP"
            />
            <MyTextInput
              label="Diocesan Network"
              name="diocesanNetwork"
              placeholder="Diocesan Network"
            />
            <MyTextInput
              label="Vendor & Support #"
              name="support"
              placeholder="Vendor & Support #"
            />
            <MyTextInput
              label="Modem Credentials"
              name="modem"
              placeholder="Modem Credentials"
            />
            <MyTextInput
              label="Local IP Range"
              name="localRange"
              placeholder="Local IP Range"
            />
            <MyTextInput label="Notes" name="notes" placeholder="Notes here" />

            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              color="blue"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
