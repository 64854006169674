import React from "react";
import { Link } from "react-router-dom";
import { Header, Segment, Button, Grid } from "semantic-ui-react";

export default function SingleLocation({ location }) {
  const { category } = location;
  const locatonCardBGColor = (category) => {
    switch (category) {
      case "CC":
        return "teal";
      case "CEM":
        return "green";
      case "DOH":
        return "yellow";
      case "DOC":
        return "red";
      default:
        return "grey";
    }
  };

  return (
    <>
      <Grid.Column style={{ margin: 10 }} color={locatonCardBGColor(category)}>
        <Segment>
          <span>{location.category}</span>
          <Header style={{ margin: 0 }} as="h3">
            {location.name}
          </Header>
          <Header
            style={{ margin: 0 }}
            color={locatonCardBGColor(category)}
            sub
          >
            {location.category}
          </Header>

          <Button
            size="small"
            as={Link}
            to={`/location/${location.id}`}
            color="blue"
          >
            View Site
          </Button>
        </Segment>
      </Grid.Column>
    </>
  );
}
