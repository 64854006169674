import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  FETCH_LOCATIONS,
  UPDATE_LOCATION,
} from "./locationConstants";

const initialState = {
  locations: [],
};

export default function locationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_LOCATION:
      return {
        ...state,
        locations: [...state.locations, payload],
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        locations: [
          ...state.locations.filter((location) => location.id !== payload.id),
          payload,
        ],
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locations: [
          ...state.locations.filter((location) => location.id !== payload),
        ],
      };
    case FETCH_LOCATIONS:
      return {
        ...state,
        locations: payload,
      };
    default:
      return state;
  }
}
