import firebase from "../config/firebase";

const db = firebase.firestore();

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp)
        data[prop] = data[prop].toDate();
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function listenToLocationsFromFirestore() {
  return db.collection("locations").orderBy("name");
}

export function listenToLocationFromFirestore(locationId) {
  return db.collection("locations").doc(locationId);
}

export function addLocationToFirestore(location) {
  return db.collection("locations").add({ ...location });
}

export function updateLocationInFirestore(location) {
  return db.collection("locations").doc(location.id).update(location);
}

export function deleteLocationInFirestore(locationId) {
  return db.collection("locations").doc(locationId).delete();
}

export function updateHardware(locationId, newHardware) {
  return db.collection("locations").doc(locationId).update({
    hardware: newHardware,
  });
}
export function updatePhones(locationId, newPhones) {
  return db.collection("locations").doc(locationId).update({
    phones: newPhones,
  });
}

//FILES
export function getLocationFiles(id) {
  return db.collection("files").where("locationID", "==", id);
}
export function downloadFile(fileName) {
  const storageRef = firebase.storage().ref();
  return storageRef
    .child(`${fileName}`)
    .getDownloadURL()
    .then(function (url) {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "download");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
}

export async function deleteFile(fileId, filename) {
  await firebase.storage().ref(filename).delete();
  return db.collection("files").doc(fileId).delete();
}

export function uploadLocationFiles(files) {
  return db.collection("files").add({
    ...files,
    uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export async function uploadFileToStorage(file, fileInfo, hashedFileName) {
  await db.collection("files").add(fileInfo);
  const storageRef = firebase.storage().ref(hashedFileName);
  return storageRef.put(file);
}

export function setUserProfileData(user) {
  return db.collection("users").doc(user.uid).set({
    displayName: user.displayName,
    email: user.email,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export function getUserProfile(userId) {
  return db.collection("users").doc(userId);
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    return await db.collection("users").doc(user.uid).update(profile);
  } catch (error) {}
}
