import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import Logo from "../../assets/DOC_LOGO.png";
import { useSelector } from "react-redux";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";

export default function NavBar() {
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <Menu inverted fixed="top">
      <Container>
        <Menu.Item as={NavLink} to="/">
          <img width={50} src={Logo} alt="Diocese IT Dept." />
        </Menu.Item>
        <Menu.Item
          content="Add New Site"
          icon="plus"
          as={NavLink}
          to="/newlocation"
        />
        {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
      </Container>
    </Menu>
  );
}
