import React from "react";
import Locations from "./Locations";
import NavBar from "../navigation/NavBar";
import { Container } from "semantic-ui-react";

export default function LocationDashboard() {
  return (
    <Container className="main">
      <NavBar />
      <Locations />
    </Container>
  );
}
