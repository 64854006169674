import React, { useState } from "react";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Button } from "semantic-ui-react";
import ModalWrapper from "../../../modals/ModalWrapper";
import { Form, Formik } from "formik";
import MyTextInput from "../../../forms/MyTextInput";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../LoadingComponent";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateLocationInFirestore } from "../../../../firestore/firestoreService";
import { closeModal } from "../../../modals/modalReducer";

export default function AddHardware(location) {
  const { loading, error } = useSelector((state) => state.async);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [originalHardware] = useState(location.hardware);
  const [hardwareCount, setHardwareCount] = useState(0);
  const initialValues = {
    id: uuidv4(),
    device: "",
    hardwareLocation: "",
    ipAddress: "",
    serial: "",
    extIP: "",
    support: "",
  };

  const validationSchema = Yup.object({
    device: Yup.string().required("Please enter a device name."),
    hardwareLocation: Yup.string(),
    ipAddress: Yup.string(),
    serial: Yup.string(),
    extIP: Yup.string(),
    support: Yup.string(),
  });

  const finalSubmitAllHardware = (location) => {
    updateLocationInFirestore(location);
    dispatch(closeModal());
    toast.success(
      "Your new hardware/hardwares have been added successfully. If your new hardware/s do not appear right away please refresh your page.",
      { autoClose: 6000 }
    );
  };

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) navigate("/error");

  return (
    <ModalWrapper size="large" header="Add Hardware">
      {hardwareCount > 0 && <p>New Hardwares Added so far: {hardwareCount}</p>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          originalHardware.push(values);
          setHardwareCount(hardwareCount + 1);
          resetForm({
            values: {
              id: uuidv4(),
              device: "",
              hardwareLocation: "",
              ipAddress: "",
              serial: "",
              extIP: "",
              support: "",
            },
          });
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <MyTextInput
              label="Device Name"
              name="device"
              placeholder="Required"
            />
            <MyTextInput
              label="Hardware Location"
              name="hardwareLocation"
              placeholder="Where is the hardware located"
            />
            <MyTextInput label="IP Address" name="ipAddress" />
            <MyTextInput label="Serial Number" name="serial" />
            <MyTextInput label="External IP" name="extIP" />
            <MyTextInput label="Support Info" name="support" />
            <Button type="submit" icon="plus" color="blue" />
          </Form>
        )}
      </Formik>
      <Button
        onClick={() => finalSubmitAllHardware(location)}
        icon="save"
        style={{ marginTop: 8 }}
        color="red"
        content="Save all new hardware to database"
      />
    </ModalWrapper>
  );
}
