import React from "react";
import NavBar from "../navigation/NavBar";
import { Formik, Form } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Button, Container, Header, Segment, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import MyTextInput from "./MyTextInput";
import MySelectInput from "./MySelectInput";
import { useSelector } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import { addLocationToFirestore } from "../../firestore/firestoreService";
import { toast } from "react-toastify";

export default function AddNewLocationForm() {
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.async);
  const initialValues = {
    id: uuidv4(),
    name: "",
    address: "",
    category: "",
    mainPhone: "",
    siteContact: "",
    internet: "",
    diocesanNetwork: "",
    support: "",
    modem: "",
    localRange: "",
    externalIP: "",
    hardware: [],
    phones: [],
    notes: "",
  };

  const locationCategory = [
    { key: "cc", value: "CC", text: "CC" },
    { key: "cem", value: "CEM", text: "CEM" },
    { key: "doh", value: "DOH", text: "DOH" },
    { key: "doc", value: "DOC", text: "DOC" },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter a name for this location"),
    address: Yup.string().required(
      "Please eneter an address for this location"
    ),
    category: Yup.string().required("Please select a category."),
    mainPhone: Yup.string().required(
      "Please enter a main contact phone number"
    ),
    internet: Yup.string(),
    diocesanNetwork: Yup.string(),
    support: Yup.string(),
    modem: Yup.string(),
    localRange: Yup.string(),
    notes: Yup.string(),
    id: Yup.string(),
  });

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) return navigate("/error");

  return (
    <Container className="main">
      <NavBar />
      <Button basic icon onClick={() => navigate("/locations")}>
        <Icon name="home" />
      </Button>
      <Segment clearing>
        <Header as="h3">Add a New Location</Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            try {
              addLocationToFirestore(values);
              toast.success("New location has been added successfully.");
              setSubmitting(false);
              navigate("/locations");
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="ui form">
              <MyTextInput
                label="Location Name"
                name="name"
                placeholder="Name of location"
              />
              <MySelectInput
                fluid
                style={{ color: "#333" }}
                name="category"
                label="Location Category"
                placeholder="Select a category here"
                options={locationCategory}
              />
              <MyTextInput
                label="Address"
                name="address"
                placeholder="Address of location"
              />
              <MyTextInput
                label="Main Phone"
                name="mainPhone"
                placeholder="Main Phone Number"
              />
              <MyTextInput
                name="siteContact"
                label="Site's Main Contact Info"
                placeholder="Enter main contact for this location"
              />
              <MyTextInput
                label="Internet Speed"
                name="internet"
                placeholder="Internet Speed"
              />
              <MyTextInput
                label="Diocesan Network"
                name="diocesanNetwork"
                placeholder="Diocesan Network"
              />
              <MyTextInput
                label="Vendor & Support #"
                name="support"
                placeholder="Vendor & Support #"
              />
              <MyTextInput
                label="Modem Credentials"
                name="modem"
                placeholder="Modem Credentials"
              />
              <MyTextInput
                label="Local IP Range"
                name="localRange"
                placeholder="Local IP Range"
              />
              <MyTextInput
                label="Notes"
                name="notes"
                placeholder="Notes here"
              />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                color="blue"
                content="Submit"
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
}
