import React from "react";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../../modals/ModalWrapper";
import { Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../forms/MyTextInput";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../LoadingComponent";
import { updatePhones } from "../../../../firestore/firestoreService";
import { closeModal } from "../../../modals/modalReducer";

export default function EditPhone({ location, p }) {
  const dispatch = useDispatch();
  const { id, misc, numName, number, provider, support } = p;
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.async);
  const originalLocation = useSelector((state) =>
    state.locations.locations.find((l) => l.id === location.id)
  );

  const { phones } = originalLocation;

  const initialValues = {
    id: id || "",
    numName: numName || "",
    number: number || "",
    provider: provider || "",
    misc: misc || "",
    support: support || "",
  };

  const validationSchema = Yup.object({
    number: Yup.string().required("Please enter a phone number."),
    numName: Yup.string(),
    support: Yup.string(),
    provider: Yup.string(),
    misc: Yup.string(),
  });

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) navigate("/error");

  return (
    <div>
      <ModalWrapper size="mini" header="Edit Phones">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            try {
              const findPhones = phones.findIndex((p) => p.id === values.id);
              phones[findPhones] = values;
              updatePhones(originalLocation.id, phones);
              toast.success(`Your phone has been updated.`);
              dispatch(closeModal());
            } catch (e) {
              toast.error(`${e.message}`);
              console.log(e.message);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="ui form">
              <MyTextInput label="Phone Number" name="number" />
              <MyTextInput label="Number Name" name="numName" />
              <MyTextInput label="Provider" name="provider" />
              <MyTextInput label="Vendor & Support" name="support" />
              <MyTextInput label="Misc." name="misc" />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                color="blue"
                content="Submit"
              />
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </div>
  );
}
